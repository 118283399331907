export const modal = {
  fontSize: '16px'
}

export const modalClose = {
  cursor: 'pointer',
  position: 'absolute',
  display: 'block',
  padding: '2px 5px',
  lineHeight: '20px',
  right: '-10px',
  top: '-10px',
  fontSize: '24px',
  background: '#ffffff',
  borderRadius: '18px',
  border: '1px solid #cfcece',
  outline: 'none'
}

export const modalHeader = {
  width: '100%',
  borderBottom: '1px solid gray',
  fontSize: '18px',
  textAlign: 'center',
  padding: '5px'
}
export const modalContent = {
  width: '100%',
  padding: '10px 10px'
}

export const modalActions = {
  width: ' 100%',
  padding: ' 10px 5px',
  margin: ' auto',
  textAlign: ' center'
}

export const modalActionBtn = {
  backgroundColor: 'transparent',
  outline: 'none',
  border: '1px solid gray',
  padding: '4px 12px',
  cursor: 'pointer'
}

export const modalDelBtn = {
  backgroundColor: 'transparent',
  outline: 'none',
  border: '1px solid gray',
  marginLeft: '10px',
  padding: '4px 12px',
  cursor: 'pointer'
}
